import React, { useState } from 'react';
// import ReactDOM from 'react-dom';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ThemeProvider from '@mui/material/styles/ThemeProvider';

import Iframe from '../../components/Iframe';
import DefaultLayout from '../../layouts/DefaultLayout';
import Seo from '../../../app/Seo';
import Page from '../../components/Page';
import theme from '../../themes/light';

const LegalDisclaimerPage = () => {
  const [iFrameHeight, setiFrameHeight] = useState('500px');

  const handleOnLoad = (arg) => {
    // console.log(`handleOnLoad, arg`, arg);
    // console.log(`arg.target`, arg.target);
    // console.log(`XXX`, arg.target.contentWindow);
    // const obj: any = ReactDOM.findDOMNode(arg.target);
    // console.log(`obj`, obj.contentWindow.document);
    // console.log(`handleOnLoad, this`, this);
    // (function (o: any) {
    //   if (o) o.style.height = o.contentWindow.document.body.scrollHeight + 'px';
    // })(this);
  };

  return (
    <>
      <Seo title='Disclaimer' />
      <DefaultLayout>
        <ThemeProvider theme={theme}>
          <Paper>
            <Container>
              <Typography
                variant='h3'
                component='h1'
                align='center'
                gutterBottom={true}
              >
                Our Disclaimer
              </Typography>
              <Iframe
                url='https://app.termly.io/document/disclaimer/7ae39c68-47d2-4a64-9dc0-6952696fe864'
                onLoad={(arg) => handleOnLoad(arg)}
                // position='absolute'
                // width='100%'
                id='myIframe'
                // className='myClassname'
                // height='100vh'
                // styles={{ height: '500px' }}
                // ref='iframe'
                width='100%'
                height={iFrameHeight}
                scrolling='yes'
              />
            </Container>
          </Paper>
        </ThemeProvider>
      </DefaultLayout>
    </>
  );
};

export default LegalDisclaimerPage;
